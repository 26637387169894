import Header from 'components/Header'
import SwapForm from 'components/SwapForm'
import Footer from 'components/Footer'

const App = () => (
  <div className="App">
    <Header />
    <SwapForm />
    <Footer />
  </div>
)

export default App
