import { useMemo } from 'react'
import { Contract } from 'ethers'
import { useWeb3React } from '@web3-react/core'

import { useActiveChainId } from 'state/network/hooks'

import { getContract } from 'web3/utils'
import { useGetConnection } from 'web3/connection'
import WSTRAX_ABI from 'constants/abis/wstrax'
import { Wstrax } from 'constants/abis/types'

import { WSTRAX_ADDRESS } from '../constants'

export function useContract<T extends Contract = Contract>(address: string | undefined, ABI: any, withSignerIfPossible = true): T | null {
  const { provider, account, chainId } = useWeb3React()
  const getConnection = useGetConnection()

  return useMemo(() => {
    if (!address || !ABI || !provider) {
      return null
    }

    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, provider, withSignerIfPossible, account, chainId, getConnection ]) as T
}

export function useWSTRAXContract(): Wstrax | null {
  const chainId = useActiveChainId()
  return useContract(WSTRAX_ADDRESS[chainId], WSTRAX_ABI)
}