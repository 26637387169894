import { ChangeEvent, useState, useCallback, useMemo, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { formatEther, parseEther } from '@ethersproject/units'
import cx from 'classnames'
import AOS from 'aos'

import { ChainId } from 'web3/chains'
import { WSTRAX_ADDRESS } from '../../constants'

import { useUserBalanceNative, useUserBalanceWrapped, useUpdateBalanceNative, useUpdateBalanceWrapped } from 'state/user/hooks'
import { useActiveNetwork } from 'state/network/hooks'

import { useWrapSTRAX, useUnwrapWSTRAX } from 'hooks/useSwap'

import { useWalletModal } from 'components/WalletModal'

import { ParticleAnimation } from 'utils/particles'

import GLOW_IMAGE from 'assets/images/glow-bottom.svg'

import type { SwapDir } from 'types'

const SwapForm = () => {
  const { openWalletModal } = useWalletModal()
  const wrapSTRAX = useWrapSTRAX()
  const unwrapWSTRAX = useUnwrapWSTRAX()
  const updateBalanceNative = useUpdateBalanceNative()
  const updateBalanceWrapped = useUpdateBalanceWrapped()

  const { account } = useWeb3React()
  const balanceNative = useUserBalanceNative()
  const balanceWrapped = useUserBalanceWrapped()
  const activeNetwork = useActiveNetwork()

  const [dir, setDir] = useState<SwapDir>('wrap')
  const [processing, setProcessing] = useState(false)
  const [amount, setAmount] = useState('')

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 1000,
      easing: 'ease-out-cubic',
    })

    const canvasElements = document.querySelectorAll('[data-particle-animation]')
    canvasElements.forEach((canvas: any) => {
      const options = {
        quantity: canvas.dataset.particleQuantity,
        staticity: canvas.dataset.particleStaticity,
        ease: canvas.dataset.particleEase,
      }
      new ParticleAnimation(canvas, options)
    })
  }, [])

  const canSwap = useMemo(() => {
    if (!amount) {
      return false
    }

    const amt = parseEther(amount)
    if (dir === 'wrap') {
      return amt.lte(balanceNative)
    }

    return amt.lte(balanceWrapped)
  }, [amount, dir, balanceNative, balanceWrapped])

  const setMaxBalance = () => {
    if (dir === 'wrap') {
      setAmount(formatEther(balanceNative))
    } else {
      setAmount(formatEther(balanceWrapped))
    }
  }

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.validity.valid) {
      return
    }

    setAmount(event.target.value)
  }

  const handleSwap = useCallback(async () => {
    if (!canSwap) {
      return
    }

    const amt = parseEther(amount)

    setProcessing(true)

    try {
      if (dir === 'wrap') {
        await wrapSTRAX(amt)
      } else {
        await unwrapWSTRAX(amt)
      }

      setAmount('')
      updateBalanceNative()
      updateBalanceWrapped()
    } catch (error) {
      console.error(`Swap error: ${error}`)
    } finally {
      setProcessing(false)
    }
  }, [
    amount,
    dir,
    canSwap,
    wrapSTRAX,
    unwrapWSTRAX,
  ])

  const renderAction = () => {
    if (!account) {
      return (
        <button className="w-full justify-center flex gap-2 rounded-md bg-purple-900 px-3 py-2 font-semibold text-white hover:bg-indigo-500" onClick={openWalletModal}>
          Connect Wallet
        </button>
      )
    }

    if (!activeNetwork) {
      return (
        <div className="w-full text-center border rounded-md border-slate-500 text-slate-500 px-3 py-2">
          Wrong network
        </div>
      )
    }

    return (
      <button className={cx('w-full rounded-md bg-purple-900 px-3 py-2 font-semibold', { 'opacity-50': !canSwap || processing, 'hover:bg-indigo-500': canSwap && !processing})} disabled={!canSwap || processing} onClick={handleSwap}>
        {processing ? 'Processing...' : (dir === 'wrap' ? 'Wrap' : 'Unwrap')}
      </button>
    )
  }

  return (
    <div className="relative w-full h-full flex flex-col py-20">
      <div className="absolute inset-0 -z-10" aria-hidden="true">
        <canvas data-particle-animation></canvas>
      </div>

      <div className="absolute inset-0 -z-10 -mx-28 pointer-events-none overflow-hidden"
        aria-hidden="true">
        <div className="absolute left-1/2 -translate-x-1/2 bottom-0 -z-10">
          <img src={GLOW_IMAGE} className="max-w-none" width="1146" height="774" alt="Hero Illustration" />
        </div>
      </div>

      <div className="pt-32 pb-16 md:pt-32 md:pb-20">

        <div className="w-full flex flex-col gap-7 max-w-xl mx-auto p-4 sm:p-6 border rounded-lg border-slate-800 bg-slate-900">
          <div className="w-full flex gap-1 items-center justify-stretch">
            <div
              className={cx('grow cursor-pointer text-center py-2', {
                'text-slate-100 rounded-md bg-purple-700': dir === 'wrap',
                'text-slate-400': dir !== 'wrap',
              })}
              onClick={() => setDir('wrap')}
            >
              Wrap
            </div>
            <div
              className={cx('grow cursor-pointer text-center py-2', {
                'text-slate-100 rounded-md bg-purple-700': dir === 'unwrap',
                'text-slate-400': dir !== 'unwrap',
              })}
              onClick={() => setDir('unwrap')}
            >
              Unwrap
            </div>
          </div>
          <div className="flex items-center">
            <div className="inline-flex text font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">Balance</div>
            <div className="ml-auto cursor-pointer text-purple-400" onClick={setMaxBalance}>
              {dir === 'wrap' ? (
                `${formatEther(balanceNative)} STRAX`
              ) : (
                `${formatEther(balanceWrapped)} wSTRAX`
              )}
            </div>
          </div>
          <div>
            <input
              className="w-full text-black rounded-md"
              value={amount}
              pattern="^([0-9]+(?:[.,][0-9]*)?)$"
              inputMode="decimal"
              onChange={handleAmountChange}
            />
          </div>
          <div>
            {renderAction()}
          </div>
        </div>
        {(account && activeNetwork && activeNetwork.blockExplorerUrls) ? (
          <div className="max-w-xl mx-auto w-full flex items-center justify-center gap-2 mt-2 flex-wrap">
            Contract address
            <a
              className="text-purple-400"
              href={`${activeNetwork.blockExplorerUrls[0]}/address/${WSTRAX_ADDRESS[activeNetwork.id as ChainId]}`}
              target="_blank"
            >
              {WSTRAX_ADDRESS[activeNetwork.id as ChainId]}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SwapForm
