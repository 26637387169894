import { useCallback, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'

import { useWSTRAXContract } from 'hooks/useContract'

import { useAppDispatch, useAppSelector } from 'state'

import {
  setBalanceNative,
  setBalanceWrapped,
} from './reducer'

export function useUpdateBalanceNative() {
  const dispatch = useAppDispatch()
  const { account, provider } = useWeb3React()

  return useCallback(async () => {
    if (!provider || !account) {
      dispatch(setBalanceNative('0'))
      return
    }

    const val = await provider.getBalance(account)
    dispatch(setBalanceNative(val.toString()))
  }, [account, provider, dispatch])
}

export function useUpdateBalanceWrapped() {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const contract = useWSTRAXContract()

  return useCallback(async () => {
    if (!contract || !account) {
      dispatch(setBalanceWrapped('0'))
      return
    }

    const val = await contract.balanceOf(account)
    dispatch(setBalanceWrapped(val.toString()))
  }, [account, contract, dispatch])
}

export function useUserBalanceNative() {
  const balance = useAppSelector(state => state.user.balanceNative)
  return useMemo(() => BigNumber.from(balance), [balance])
}

export function useUserBalanceWrapped() {
  const balance = useAppSelector(state => state.user.balanceWrapped)
  return useMemo(() => BigNumber.from(balance), [balance])
}
