import STRATIS_ICON from 'assets/images/networks/stratis_logo_white.svg'
import ConnectButton from 'components/ConnectButton'

const Header = () => (
  <header className="absolute w-full z-30">
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="flex items-center justify-between h-16 md:h-20">
        <div className="flex-1">
          <a className="inline-flex items-center" href="index.html" aria-label="Cruip">
            <img className="max-w-none" src={STRATIS_ICON} width="38" height="38" alt="Stellar" />
            <span className="ml-3 hidden md:block">Stratis wSTRAX dApp</span>
          </a>
        </div>

        <ConnectButton />
      </div>
    </div>
  </header>
)

export default Header
