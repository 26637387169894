import { useCallback } from 'react'

import useInterval from 'hooks/useInterval'

import {
  useUpdateBalanceNative,
  useUpdateBalanceWrapped,
} from './hooks'

export default function Updater() {
  const updateBalanceNative = useUpdateBalanceNative()
  const updateBalanceWrapped = useUpdateBalanceWrapped()

  const updateData = useCallback(async () => {
    updateBalanceNative()
    updateBalanceWrapped()
  }, [
    updateBalanceNative,
    updateBalanceWrapped,
  ])

  useInterval(updateData, 3000)

  return null
}
