import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  balanceNative: string
  balanceWrapped: string
}

export const initialState: UserState = {
  balanceNative: '0',
  balanceWrapped: '0',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setBalanceNative(state, action: PayloadAction<string>) {
      state.balanceNative = action.payload
    },
    setBalanceWrapped(state, action: PayloadAction<string>) {
      state.balanceWrapped = action.payload
    },
  },
})

export const {
  setBalanceNative,
  setBalanceWrapped,
} = userSlice.actions

export default userSlice.reducer

