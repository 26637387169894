import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from '@ethersproject/bignumber'

import { useWSTRAXContract } from './useContract'

export function useWrapSTRAX() {
  const { account, provider } = useWeb3React()
  const contract = useWSTRAXContract()

  return useCallback(async (amount: BigNumber) => {
    if (!account || !contract || !provider) {
      return
    }

    const tx = await provider.getSigner(account).sendTransaction({
      to: contract.address,
      value: amount,
    })
    await tx.wait()
  }, [account, provider, contract])
}

export function useUnwrapWSTRAX() {
  const { account } = useWeb3React()
  const contract = useWSTRAXContract()

  return useCallback(async (amount: BigNumber) => {
    if (!account || !contract) {
      return
    }

    const tx = await contract.withdraw(amount)
    await tx.wait()
  }, [account, contract])
}
